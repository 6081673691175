import {FC} from 'react';
import * as React from 'react';

import {SkeletonCard, SkeletonLabel} from 'components';

import './skeleton.scss';

export const SkeletonPage: FC = () => (
  <div className='skeleton-page'>
    <SkeletonLabel />
    <SkeletonCard />
    <SkeletonCard />
    <SkeletonLabel />
    <SkeletonCard />
    <SkeletonCard />
    <SkeletonCard />
    <SkeletonLabel />
    <SkeletonCard />
    <SkeletonLabel />
    <SkeletonCard />
    <SkeletonCard />
    <SkeletonCard />
    <SkeletonLabel />
    <SkeletonCard />
  </div>
);
