import {ContentItem, getGoal, getListOfMonths, getMonthlyPayment, getSecretKey, ItemDTO} from 'api';
import {enc, RC4} from 'crypto-js';
import {linearRegression} from 'simple-statistics';

import 'simple-statistics.d.ts';

export const calculateCurrentBalance = (): number => {
  const months = getListOfMonths();
  let balance = 0;
  months.map(month => {
    const monthlyData = getMonthlyPayment(month);
    const parsedData = JSON.parse(monthlyData) as ItemDTO[];
    const sumOfExpenses = parsedData.reduce((acc, curItem) => acc + Number(curItem.a), balance);
    balance = sumOfExpenses;
    return '';
  });
  return balance;
};

export const createFileName = (): string => {
  const currentData = new Date().toISOString().substring(0, 10);

  return `EBP_${currentData}.json`;
};

export const downloadPaymentData = (): void => {
  const listOfMonths = getListOfMonths();
  const content: ContentItem[] = [];
  listOfMonths.map(month => {
    const monthlyData = getMonthlyPayment(month);
    if (monthlyData) content.push({key: month, data: encryptData(monthlyData)});
    return '';
  });

  content.push({key: 'secretKey', data: encryptData(getSecretKey())});
  content.push({key: 'goal', data: encryptData(getGoal().toString())});

  const blob = new Blob([JSON.stringify(content)], {type: 'text/plain'});
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = createFileName();
  link.href = url;
  link.click();
};

function zip<T, U>(a: T[], b: U[]): Array<[T, U]> {
  return a.map((value, index) => [value, b[index]]);
}

export const getGoalPredictionWithLinearRegression = (): Date => {
  const listOfMonths = getListOfMonths();
  const dates: number[] = [];
  const amounts: number[] = [];

  listOfMonths.map(month => {
    const monthlyData = getMonthlyPayment(month);
    const parsedData = JSON.parse(monthlyData) as ItemDTO[];
    return parsedData.map(transaction => {
      const formatDate = `${month}-${transaction.d}`;
      dates.push(new Date(formatDate).getTime());
      amounts.push(Number(transaction.a));
      return '';
    });
  });

  const balances = [];
  let balance = 0;
  for (let i = 0; i < dates.length; i += 1) {
    balance += amounts[i];
    balances.push(balance);
  }

  const regression = linearRegression(zip(dates, balances));
  const slope = regression.m;
  const intercept = regression.b;
  const targetAmount = getGoal();
  const targetDate = new Date((targetAmount - intercept) / slope);

  return targetDate;
};

export const encryptData = (textToEncode: string): string => {
  const key = sessionStorage.getItem('password') ?? '';

  const ciphertext = RC4.encrypt(textToEncode, key).toString();
  return ciphertext;
};

export const decryptData = (ciphertext: string): string => {
  const key = sessionStorage.getItem('password') ?? '';
  try {
    const originalText = RC4.decrypt(ciphertext, key).toString(enc.Utf8);
    return originalText;
  } catch (err: any) {
    return err.toString();
  }
};

interface TransactionType {
  type: string;
  amount: number;
}

export const monteCarloSimulationForTransactionsOverYears = (
  iterations: number,
  months: number,
  avgIncome: number,
  avgExpenses: number
): number => {
  let successfulScenarios = 0;

  for (let i = 0; i < iterations; i += 1) {
    let totalIncome = 0;
    let totalExpenses = 0;

    for (let j = 0; j < months; j += 1) {
      const incomeRange = (Math.random() * 0.5 + 0.75) * avgIncome;
      const expenseRange = (Math.random() * 0.5 + 0.75) * avgExpenses;
      totalIncome += incomeRange;
      totalExpenses += expenseRange;
    }
    if (totalIncome - totalExpenses >= Math.abs(getGoal() - calculateCurrentBalance())) {
      successfulScenarios += 1;
    }
  }
  const probability = successfulScenarios / iterations;
  return probability;
};

export const getChancesToReachGoalWithMonteCarloSimulation = (
  months: number,
  iterations: number,
  nrOfPreviousMonths: number
): number => {
  const listOfMonths = getListOfMonths().slice(0, nrOfPreviousMonths);
  const transactions: TransactionType[] = [];

  // accumulate previous transaction data
  listOfMonths.map(month => {
    const monthlyData = getMonthlyPayment(month);
    const parsedData = JSON.parse(monthlyData) as ItemDTO[];
    return parsedData.map(transaction => {
      transaction.a[0] === '-'
        ? transactions.push({
            type: 'Expense',
            amount: Number(transaction.a.substring(1)),
          })
        : transactions.push({
            type: 'Income',
            amount: Number(transaction.a),
          });

      return '';
    });
  });

  // calculate average values of prev data
  const avgIncome =
    transactions
      .filter(transaction => transaction.type === 'Income')
      .reduce((total, transaction) => total + transaction.amount, 0) / transactions.length;
  const avgExpenses =
    transactions
      .filter(transaction => transaction.type === 'Expense')
      .reduce((total, transaction) => total + transaction.amount, 0) / transactions.length;

  // run Monte Carlo Simulation based on ...
  const monteCarloSimulationProbability = monteCarloSimulationForTransactionsOverYears(
    iterations,
    months,
    avgIncome,
    avgExpenses
  );

  return monteCarloSimulationProbability;
};
