import {FC} from 'react';

import {ChartItem, ItemDTO} from 'api';
import {getGoal, getListOfMonths, getMonthlyPayment} from 'api/localStorageAPI';
import {calculateCurrentBalance} from 'assets';
import {ExpensesPie, LoaderContainer, ProgressBar} from 'components';

import './dashboard.scss';

export const Dashboard: FC = () => {
  const months = getListOfMonths();

  const currentBalance = calculateCurrentBalance();

  return (
    <LoaderContainer show={false}>
      <div id='dashboard'>
        DASHBOARD
        <ProgressBar target={getGoal()} current={currentBalance} />
        {months.map((month, index) => {
          const items: ChartItem[] = [];
          let totalExpenses = 0;
          const monthlyData = getMonthlyPayment(month);
          const parsedData = JSON.parse(monthlyData) as ItemDTO[];
          parsedData
            .filter(item => item.a[0] === '-')
            .map(payment => {
              const itemFound = items.findIndex(obj => obj.name === payment.c);
              const paymentValue = Number(-payment.a);
              totalExpenses += paymentValue;
              return itemFound > -1
                ? (items[itemFound].value += Number(paymentValue))
                : items.push({name: payment.c ?? 'others', value: Number(paymentValue)});
            });
          return (
            <ExpensesPie key={index} items={items} totalExpenses={totalExpenses} month={month} />
          );
        })}
      </div>
    </LoaderContainer>
  );
};
