import {FC} from 'react';

import {Cell, Legend, Pie, PieChart} from 'recharts';

import './expenses-pie.scss';

interface ItemPie {
  name: string;
  value: number;
}

interface ExpensesPieProps {
  items: ItemPie[];
  totalExpenses: number;
  month: string;
}
const COLORS = ['#E1544A', '#7c2e29', '#58a775', '#00A2ED', '#f1b0ac', '#b6dec5'];

export const ExpensesPie: FC<ExpensesPieProps> = ({totalExpenses, month, items}) => (
  <div className='expense-pie-card'>
    <div className='total-label'>Total expenses</div>
    <div className='total-value'>{Math.round(totalExpenses * 100) / 100} Lei</div>
    <div className='month'>{month}</div>
    <div className='hor-bar' />
    <div className='pie'>
      <PieChart width={400} height={250}>
        <Legend layout='vertical' align='right' verticalAlign='top' />
        <Pie
          data={items}
          dataKey='value'
          nameKey='name'
          cx='50%'
          cy='50%'
          innerRadius={0}
          outerRadius={80}
          fill='#82ca9d'
          label
        >
          {items.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </div>
  </div>
);
