import {FC, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import {getSecretKey, setSecretKey} from 'api';
import {Button} from 'components';

import './auth.scss';

export const Authorize: FC = () => {
  const {state} = useLocation();
  const navigate = useNavigate();

  const [pass, setPass] = useState('');
  const [isSubmited, setIsSubmited] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem('password') && getSecretKey() === 'EBPkey') {
      navigate(state.from);
    }
  }, [pass, isSubmited]);
  return (
    <div className='auth-container'>
      <div className='auth-layout'>
        Authorize
        <input
          data-testid='password-input'
          type='password'
          onChange={ev => {
            setPass(ev.target.value);
            sessionStorage.setItem('password', ev.target.value);
          }}
        />
        {!localStorage.getItem('secretKey') && (
          <Button
            variant='primary'
            isDisabled={false}
            iconPosition='none'
            icon={undefined}
            hidden={false}
            onClickAction={() => {
              setSecretKey(pass);
              setIsSubmited(true);
            }}
          >
            Set a new key
          </Button>
        )}
      </div>
    </div>
  );
};
