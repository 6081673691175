import {FC, useState} from 'react';

import {
  getChancesToReachGoalWithMonteCarloSimulation,
  getGoalPredictionWithLinearRegression,
} from 'assets';

import './progress-bar.scss';

interface ProgressBarProps {
  target: number;
  current: number;
}

export const ProgressBar: FC<ProgressBarProps> = ({current, target}) => {
  const currentDate = new Date();
  const predictionDate = getGoalPredictionWithLinearRegression();
  const [nrOfMonths, setNrOfMonths] = useState(1);

  const howLongToReachGoal = Math.ceil(
    (predictionDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24)
  );

  const onChangeMonths = (event: any): void => {
    setNrOfMonths(event.target.value);
  };

  return (
    <div className='progress-card'>
      <div className='title'>
        <div>Balance Progress</div>
        <div>
          Chances to reach your goal next{' '}
          <input
            type='number'
            defaultValue={nrOfMonths}
            onChange={onChangeMonths}
            className='input-months'
          />{' '}
          month is {getChancesToReachGoalWithMonteCarloSimulation(nrOfMonths, 100, 1) * 100}%
        </div>
      </div>
      <div className={current >= 0 ? 'current-value' : 'current-value-neg'}>{current} Lei</div>
      <div className='bar-parent'>
        <div className='bar-child' style={{width: `${((current / target) * 100) % 100}%`}} />
      </div>
      <div className='target-value'>Expected target: {target} Lei</div>
      <div className='target-value'>Your target will be reached in {howLongToReachGoal} days</div>
    </div>
  );
};
