import {FC} from 'react';

import './skeleton-card.scss';

export const SkeletonCard: FC = () => (
  <div>
    <div className='skeleton-container'>
      <div className='skeleton category' />
      <div className='description'>
        <div className='skeleton line' />
        <div className='skeleton line' />
      </div>
      <div className='amount'>
        <div className='skeleton line' />
      </div>
    </div>
  </div>
);
