import {FC} from 'react';
import * as React from 'react';

import {LoaderContainer} from 'components';

import './contact.scss';

export const Contact: FC = () => (
  <div className='contact-page'>
    CONTACT PAGE
    <LoaderContainer show>ds</LoaderContainer>
  </div>
);
