import {FC} from 'react';
import {Navigate, Outlet, Route, Routes} from 'react-router-dom';

import {Header} from 'components';
import {Authorize, Dashboard, SkeletonPage, StoryBook} from 'pages';
import {Contact} from 'pages/contact';
import {Primary} from 'pages/primary';
import {Secondary} from 'pages/secondary';
import {AuthGuard} from './AuthGuard';

import './main.scss';

export const Main: FC = () => (
  <Routes>
    <Route path='auth' element={<Authorize />} />
    <Route
      path='/'
      element={
        <>
          <Header />
          <div id='page-container'>
            <Outlet />
          </div>
        </>
      }
    >
      <Route element={<AuthGuard />}>
        <Route path='dashboard' element={<Dashboard />} />
        <Route path='primary' element={<Primary />} />
        <Route path='secondary' element={<Secondary />} />
        <Route path='contact' element={<Contact />} />
        <Route path='storybook' element={<StoryBook />} />
        <Route path='storybook/skeleton' element={<SkeletonPage />} />
        <Route index element={<Navigate to='/dashboard' />} />
      </Route>
      <Route path='*' element={<div>invalid page</div>} />
    </Route>
  </Routes>
);
