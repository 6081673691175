import {FC, PropsWithChildren} from 'react';

import {LogoLightEBP} from 'components';

import './navbar.scss';

interface LinkType {
  to: string;
  text: string;
}

type WidthVariant = 'HD' | 'FHD' | 'QHD' | 'UHD';

interface NavbarTypes {
  logoLink?: string;
  navLinks?: LinkType[];
  handleLink: (to: string) => void;
  currentPath: string;
  maxWidth?: WidthVariant;
}

export const Navbar: FC<PropsWithChildren<NavbarTypes>> = ({
  logoLink = '/',
  navLinks,
  handleLink,
  currentPath,
  maxWidth,
  children,
}) => {
  const isCurrentPath = (path: string): boolean => {
    if (currentPath === '/' && path === '/') return true;
    if (path !== '/') return currentPath.includes(path);
    return false;
  };

  return (
    <div className='navBar'>
      <div
        className={maxWidth ? `navBarContainer width width-${maxWidth ?? ''}` : 'navBarContainer'}
      >
        <div className='navBarFlex'>
          <div
            className='navBarLogo'
            onClick={() => handleLink(logoLink)}
            data-testid='navbar-logo'
          >
            <LogoLightEBP />
          </div>

          {navLinks?.map(({to, text}) => (
            <div
              className={isCurrentPath(to) ? 'navBarLinks currentLink' : 'navBarLinks'}
              key={`${to}${text}`}
              onClick={() => handleLink(to)}
            >
              {text}
            </div>
          ))}
        </div>
        <div className='navBarChildren'>{children}</div>
      </div>
    </div>
  );
};
