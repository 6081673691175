/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react';

import './skeleton-label.scss';

export const SkeletonLabel: FC = () => (
  <div className='skeleton-label'>
    <div className='skeleton label' />
  </div>
);
