import {decryptData, encryptData} from 'assets';
import {ItemDTO} from './types';

export const getMonthlyPayment = (yearMonth: string): any =>
  decryptData(localStorage.getItem(yearMonth) ?? '');

export const saveMonthlyPayment = (yearMonth: string, data: ItemDTO[]): void => {
  const oldData = localStorage.getItem(yearMonth);

  if (!oldData) localStorage.setItem(yearMonth, encryptData(JSON.stringify(data)));
  else {
    const newData = (JSON.parse(decryptData(oldData)) as ItemDTO[]).concat(data);
    localStorage.setItem(yearMonth, encryptData(JSON.stringify(newData)));
  }
};

export const deleteMonthlyItems = (yearMonth: string): void => {
  localStorage.removeItem(yearMonth);
};

export const setItem = (key: string, data: string): void => {
  localStorage.setItem(key, data);
};

export const deleteAllItems = (): void => {
  const months: string[] = getListOfMonths();
  months.map(month => localStorage.removeItem(month));
};

export const getSecretKey = (): any => decryptData(localStorage.getItem('secretKey') ?? '');

export const setSecretKey = (secretKey: string): void => {
  localStorage.setItem('secretKey', encryptData(secretKey));
};

export const getGoal = (): number => Number(decryptData(localStorage.getItem('goal') ?? ''));

export const setGoal = (goal: string): void => {
  localStorage.setItem('goal', encryptData(goal));
};

export const getListOfMonths = (): string[] => {
  const keys = [];
  for (let i = 0; i < localStorage.length; i += 1) {
    const key = localStorage.key(i);
    if (key && key !== 'secretKey' && key !== 'goal') keys.push(key);
  }
  return keys.sort((a, b) => (a < b ? 1 : -1));
};
