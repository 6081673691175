import {FC, useState} from 'react';
import * as React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import {deleteAllItems} from 'api';
import {downloadPaymentData} from 'assets';
import {AddItemForm, Button, Navbar, SetGoalForm, UploadDataForm} from 'components';

import './header.scss';

export const Header: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isAddItemModalOpen, setIsAddItemModalOpen] = useState(false);
  const [isUploadDataModalOpen, setIsUploadDataModalOpen] = useState(false);
  const [isSetGoalModalOpen, setIsSetGoalModalOpen] = useState(false);

  const navLinks = [
    {
      to: '/dashboard',
      text: 'Dashboard',
    },
    {
      to: '/primary',
      text: 'Primary expenses',
    },
    {
      to: '/secondary',
      text: 'Secondary expenses',
    },
    {
      to: '/storybook',
      text: 'Storybook',
    },
  ];

  return (
    <Navbar
      handleLink={to => navigate(to)}
      currentPath={location.pathname}
      navLinks={navLinks}
      logoLink='/'
      maxWidth='QHD'
    >
      <div className='header-buttons'>
        <Button
          variant='danger'
          isDisabled={false}
          iconPosition='none'
          icon={undefined}
          hidden={false}
          onClickAction={() => deleteAllItems()}
        >
          Clear all items
        </Button>
        <Button
          variant='tertiary'
          isDisabled={false}
          iconPosition='none'
          icon={undefined}
          hidden={false}
          onClickAction={() => setIsUploadDataModalOpen(true)}
        >
          Upload
        </Button>
        <Button
          variant='tertiary'
          isDisabled={false}
          iconPosition='none'
          icon={undefined}
          hidden={false}
          onClickAction={() => downloadPaymentData()}
        >
          Download current
        </Button>
        <Button
          variant='tertiary'
          isDisabled={false}
          iconPosition='none'
          icon={undefined}
          hidden={false}
          onClickAction={() => setIsSetGoalModalOpen(true)}
        >
          Change Goal
        </Button>
        <Button
          variant='primary'
          isDisabled={false}
          iconPosition='none'
          icon={undefined}
          hidden={false}
          onClickAction={() => setIsAddItemModalOpen(true)}
        >
          +
        </Button>
      </div>
      {isAddItemModalOpen && <AddItemForm onClose={() => setIsAddItemModalOpen(false)} />}
      {isUploadDataModalOpen && <UploadDataForm onClose={() => setIsUploadDataModalOpen(false)} />}
      {isSetGoalModalOpen && <SetGoalForm onClose={() => setIsSetGoalModalOpen(false)} />}
    </Navbar>
  );
};
