import {FC} from 'react';
import * as React from 'react';
import Moment from 'react-moment';

import './stamp-timeline.scss';

interface StampTimelineInteface {
  date: string;
}

export const StampTimeline: FC<StampTimelineInteface> = ({date}) => (
  <div className='stamp-container'>
    <Moment format='ddd, MMMM Do'>{date}</Moment>
  </div>
);
