import React, {FC, PropsWithChildren, useState} from 'react';

import {saveMonthlyPayment} from 'api';
import {Button} from 'components';

import './add-item-form.scss';

export interface AddModalProps {
  onClose: () => void;
}

export const AddItemForm: FC<PropsWithChildren<AddModalProps>> = (props: AddModalProps) => {
  const today = new Date().toISOString().substring(0, 10);
  const [isExpenseOrIncome, setIsExpenseOrIncome] = useState(true);
  const [isPrimaryOrSecondary, setPrimaryOrSecondary] = useState(true);
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('food');
  const [amount, setAmount] = useState('');
  const [date, setDate] = useState(today);

  const {onClose} = props;

  const onChangeDescription = (event: any): void => {
    setDescription(event.target.value);
  };
  const onChangeDate = (event: any): void => {
    setDate(event.target.value);
  };
  const onChangeCategory = (event: any): void => {
    setCategory(event.target.value);
  };
  const onChangeAmount = (event: any): void => {
    setAmount(event.target.value);
  };

  const handleSave = (): void => {
    const currentYearMonth = date.substring(0, 7);
    const paymentData = [
      {
        x: description,
        d: date.substring(8, 10),
        a: (isExpenseOrIncome ? '-' : '') + amount,
        p: isPrimaryOrSecondary,
        c: isExpenseOrIncome ? category : undefined,
      },
    ];
    saveMonthlyPayment(currentYearMonth, paymentData);
    onClose();
  };

  return (
    <div className='form-background' onClick={onClose}>
      <div className='form-layout' onClick={e => e.stopPropagation()}>
        <h3>Add another expense/income</h3>
        <div className='input-field'>
          <div className='input-button'>
            &nbsp;&nbsp;
            <Button
              variant={isExpenseOrIncome ? 'primary' : 'secondary'}
              isDisabled={false}
              iconPosition='none'
              icon={undefined}
              hidden={false}
              onClickAction={() => setIsExpenseOrIncome(true)}
            >
              Expense
            </Button>
            &nbsp;&nbsp;
            <Button
              variant={isExpenseOrIncome ? 'secondary' : 'primary'}
              isDisabled={false}
              iconPosition='none'
              icon={undefined}
              hidden={false}
              onClickAction={() => setIsExpenseOrIncome(false)}
            >
              Income
            </Button>
          </div>
        </div>
        <div className='input-field' onChange={onChangeDescription}>
          Description
          <input type='text' placeholder='Describe your transaction' />
        </div>
        <div className='input-field' onChange={onChangeAmount}>
          Amount
          <input type='number' step='.01' placeholder='2.54' />
        </div>
        <div className='input-field'>
          Date
          <input type='date' defaultValue={today} onChange={onChangeDate} />
        </div>

        <div className='input-field'>
          Type
          <div className='input-button'>
            <Button
              variant={isPrimaryOrSecondary ? 'primary' : 'secondary'}
              isDisabled={false}
              iconPosition='none'
              icon={undefined}
              hidden={false}
              onClickAction={() => {
                setPrimaryOrSecondary(true);
                setCategory('food');
              }}
            >
              Primary
            </Button>
            &nbsp;&nbsp;
            <Button
              variant={isPrimaryOrSecondary ? 'secondary' : 'primary'}
              isDisabled={false}
              iconPosition='none'
              icon={undefined}
              hidden={false}
              onClickAction={() => {
                setPrimaryOrSecondary(false);
                setCategory('holiday');
              }}
            >
              Secondary
            </Button>
          </div>
        </div>
        {isExpenseOrIncome ? (
          <div className='input-field'>
            Category
            <select name='category' onChange={onChangeCategory}>
              {isPrimaryOrSecondary ? (
                <>
                  <option value='food'>food</option>
                  <option value='housing'>housing</option>
                  <option value='transport'>transport</option>
                  <option value='medical'>medical</option>
                  <option value='utilities'>utilities</option>
                  <option value='others'>others</option>
                </>
              ) : (
                <>
                  <option value='holiday'>holiday</option>
                  <option value='entertainment'>entertainment</option>
                  <option value='extra'>extra</option>
                </>
              )}
            </select>
          </div>
        ) : (
          <div />
        )}

        <div className='actions'>
          <Button
            variant='danger'
            isDisabled={false}
            iconPosition='none'
            icon={undefined}
            hidden={false}
            onClickAction={onClose}
          >
            Close
          </Button>
          <Button
            variant='success'
            isDisabled={false}
            iconPosition='none'
            icon={undefined}
            hidden={false}
            onClickAction={handleSave}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};
