import {FC} from 'react';
import {BrowserRouter} from 'react-router-dom';

import {Main} from 'main';

import './assets';

export const App: FC = () => (
  <div id='app-container'>
    <BrowserRouter>
      <Main />
    </BrowserRouter>
  </div>
);

export default App;
