import React, {FC, PropsWithChildren} from 'react';

import './loader-container.scss';

interface LoaderContainerProps {
  show: boolean;
}

export const LoaderContainer: FC<PropsWithChildren<LoaderContainerProps>> = ({show, children}) => {
  const loader = <div className='loader' />;

  return show ? <div className='loader-page'>{loader}</div> : <>{children}</>;
};
