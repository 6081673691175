import {FC} from 'react';
import {useNavigate} from 'react-router-dom';

import {
  Button,
  ExpensesPie,
  ItemTimeline,
  LoaderContainer,
  Navbar,
  ProgressBar,
  SkeletonCard,
} from 'components';
import {StampTimeline} from 'components/stamp-timeline';

import './storybook.scss';

export const StoryBook: FC = () => {
  const navigate = useNavigate();
  return (
    <LoaderContainer show={false}>
      <div className='storybook'>
        STORYBOOK
        <div>
          Progress bar
          <ProgressBar target={2000} current={1850} />
        </div>
        <div>
          Buttons: &nbsp;
          <Button
            variant='primary'
            isDisabled={false}
            iconPosition='none'
            icon={undefined}
            hidden={false}
          >
            Primary
          </Button>
          <Button variant='primary' isDisabled iconPosition='none' icon={undefined} hidden={false}>
            Primary Disabled
          </Button>
          <Button
            variant='secondary'
            isDisabled={false}
            iconPosition='none'
            icon={undefined}
            hidden={false}
          >
            Secondary
          </Button>
          <Button
            variant='secondary'
            isDisabled
            iconPosition='none'
            icon={undefined}
            hidden={false}
          >
            Secondary Disabled
          </Button>
          <Button
            variant='danger'
            isDisabled={false}
            iconPosition='none'
            icon={undefined}
            hidden={false}
          >
            Danger
          </Button>
          <Button variant='danger' isDisabled iconPosition='none' icon={undefined} hidden={false}>
            Danger Disabled
          </Button>
          <Button
            variant='success'
            isDisabled={false}
            iconPosition='none'
            icon={undefined}
            hidden={false}
          >
            Success
          </Button>
          <Button variant='success' isDisabled iconPosition='none' icon={undefined} hidden={false}>
            Success Disabled
          </Button>
          <Button
            variant='tertiary'
            isDisabled={false}
            iconPosition='none'
            icon={undefined}
            hidden={false}
          >
            Tertiary
          </Button>
          <Button variant='tertiary' isDisabled iconPosition='none' icon={undefined} hidden={false}>
            Tertiary Disabled
          </Button>
        </div>
        <div>
          Skeleton card for skeleton loading
          <SkeletonCard />
          Also see the skeleton page for My expenses
          <Button
            variant='tertiary'
            isDisabled={false}
            iconPosition='none'
            icon={undefined}
            hidden={false}
            onClickAction={() => navigate('skeleton')}
          >
            My expenses(Skeleton)
          </Button>
        </div>
        <div>
          Page loader
          <LoaderContainer show />
        </div>
        <div>
          Item timeline and Stamp timeline
          <StampTimeline date='1900-01-01T14:48:00.000Z' />
          <ItemTimeline amount='250' type='income' category='category' description='Description' />
        </div>
        <div>
          Navbar
          <Navbar
            handleLink={() => undefined}
            currentPath=''
            navLinks={[
              {
                to: '/link',
                text: 'Page description',
              },
              {
                to: '/link',
                text: 'Other page description',
              },
            ]}
            logoLink='/'
            maxWidth='QHD'
          />
        </div>
        <div>
          Expenses Piechart
          <ExpensesPie
            items={[
              {
                name: 'Food',
                value: 4567,
              },
              {
                name: 'Clothes',
                value: 2400,
              },
              {
                name: 'Transport',
                value: 1398,
              },
            ]}
            totalExpenses={8365}
            month='January'
          />
        </div>
      </div>
    </LoaderContainer>
  );
};
