import {FC} from 'react';
import * as React from 'react';

import './item-timeline.scss';

interface ItemTimelineInteface {
  description?: string;
  amount: string;
  type: 'income' | 'expense';
  category:
    | 'housing'
    | 'transport'
    | 'food'
    | 'utilities'
    | 'insurance'
    | 'medical'
    | 'saving'
    | 'clothes'
    | 'holiday'
    | string;
}

const amountWithDecimals = (amount: string): string =>
  amount.includes('.') ? amount : `${amount}.00`;

export const ItemTimeline: FC<ItemTimelineInteface> = ({amount, category, type, description}) => (
  <div className='item-container'>
    <div className='category'>{category.substring(0, 9)}</div>
    <div className='description'>{description}</div>
    <div className='amount'>
      {type === 'income' ? '+' : ''}
      {amountWithDecimals(amount)}
    </div>
  </div>
);
