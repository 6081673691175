import React, {FC, PropsWithChildren, useState} from 'react';

import {setGoal} from 'api';
import {Button} from 'components';

import './set-goal-form.scss';

export interface SetGoalModalProps {
  onClose: () => void;
}

export const SetGoalForm: FC<PropsWithChildren<SetGoalModalProps>> = (props: SetGoalModalProps) => {
  const [amount, setAmount] = useState('');

  const {onClose} = props;

  const onChangeAmount = (event: any): void => {
    setAmount(event.target.value);
  };

  const handleSave = (): void => {
    setGoal(amount);
    onClose();
  };

  return (
    <div className='goal-form-background' onClick={onClose}>
      <div className='form-layout' onClick={e => e.stopPropagation()}>
        <h3>Set the goal</h3>

        <div className='input-field' onChange={onChangeAmount}>
          Amount
          <input type='number' step='.01' placeholder='e.g.: 4000' />
        </div>
        <div className='actions'>
          <Button
            variant='danger'
            isDisabled={false}
            iconPosition='none'
            icon={undefined}
            hidden={false}
            onClickAction={onClose}
          >
            Close
          </Button>
          <Button
            variant='success'
            isDisabled={false}
            iconPosition='none'
            icon={undefined}
            hidden={false}
            onClickAction={handleSave}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};
