import {FC, PropsWithChildren} from 'react';

import {IconVariants} from 'components';

import './button.scss';

export type ButtonVariants = 'primary' | 'secondary' | 'danger' | 'success' | 'tertiary';
export type ButtonIconPosition = 'none' | 'left' | 'right';

export interface ButtonProps {
  variant: ButtonVariants;
  onClickAction?: () => void;
  isDisabled: boolean;
  iconPosition: ButtonIconPosition;
  icon: IconVariants | undefined;
  hidden: boolean;
  children: JSX.Element | string;
}

export const Button: FC<PropsWithChildren<ButtonProps>> = ({
  variant,
  onClickAction,
  isDisabled,
  iconPosition,
  icon,
  hidden,
  children,
}: ButtonProps): JSX.Element => (
  <button
    type='button'
    disabled={isDisabled}
    onClick={onClickAction}
    hidden={hidden}
    className={`button ${variant}`}
  >
    {iconPosition === 'left' && icon}
    {children}
    {iconPosition === 'right' && icon}
  </button>
);
