import React, {FC, PropsWithChildren, useState} from 'react';

import {ContentItem, setItem} from 'api';
import {Button} from 'components';

import './upload-data-form.scss';

export interface UploadDataModalProps {
  onClose: () => void;
}
export const handleSave = (fileData: string, onClose: () => void): void => {
  const newData: ContentItem[] = JSON.parse(fileData);

  newData.map(monthObject => {
    if (monthObject.key === 'goal') return setItem('goal', monthObject.data);
    if (monthObject.key === 'secretKey') return setItem('secretKey', monthObject.data);
    return setItem(monthObject.key, monthObject.data);
  });

  onClose();
};
export const UploadDataForm: FC<PropsWithChildren<UploadDataModalProps>> = (
  props: UploadDataModalProps
) => {
  const {onClose} = props;
  const [fileData, setFileData] = useState('');

  const onChangeFile = (event: any): void => {
    const file = event.target.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = ev => {
      const fileContent = ev.target?.result;
      if (fileContent) {
        if (typeof fileContent === 'string') setFileData(fileContent);
      }
    };
    reader.readAsText(file);
  };

  return (
    <div className='upload-form-background' onClick={onClose}>
      <div className='form-layout' onClick={e => e.stopPropagation()}>
        <h3>Upload Data</h3>

        <div className='input-field'>
          Choose a json file
          <input type='file' accept='.json' onChange={onChangeFile} />
        </div>
        <div className='actions'>
          <Button
            variant='danger'
            isDisabled={false}
            iconPosition='none'
            icon={undefined}
            hidden={false}
            onClickAction={onClose}
          >
            Close
          </Button>
          <Button
            variant='success'
            isDisabled={false}
            iconPosition='none'
            icon={undefined}
            hidden={false}
            onClickAction={() => handleSave(fileData, onClose)}
          >
            Upload
          </Button>
        </div>
      </div>
    </div>
  );
};
