import {FC} from 'react';
import * as React from 'react';

import {getListOfMonths, getMonthlyPayment, ItemDTO} from 'api';
import {ItemTimeline, StampTimeline} from 'components';

import './secondary.scss';

export const Secondary: FC = () => {
  let day = '1900-01-01T14:48:00.000Z';

  const months = getListOfMonths();

  if (!months) return <div>There is no data</div>;
  return (
    <div className='secondary-page'>
      {months.map(month => {
        const monthlyData = getMonthlyPayment(month);
        const parsedData = JSON.parse(monthlyData) as ItemDTO[];

        return parsedData
          .filter(item => !item.p)
          .sort((a, b) => (a.d < b.d ? 1 : -1))
          .map((item, index) => {
            const itemDate = new Date(`${month}-${item.d} 14:48 UTC`).toISOString();

            const showStamp = day !== itemDate;
            day = itemDate;

            return (
              <>
                {showStamp && <StampTimeline date={itemDate} />}
                <ItemTimeline
                  key={index}
                  amount={item.a}
                  type={item.a[0] === '-' ? 'expense' : 'income'}
                  category={item.c ?? 'primary'}
                  description={item.x}
                />
              </>
            );
          });
      })}
    </div>
  );
};
